// 引入库
import React, {Component,lazy,Suspense} from "react";
import {Route, Switch, withRouter} from "react-router-dom";
// 导入特效、动画相关的库
import {CSSTransition, TransitionGroup} from "react-transition-group";

// 引入样式文件
import "./Pages.css";

// 引入普通组件
import Footer from "../components/Footer/Footer";
import Loading from "../components/Loading/loading";

// 引入路由组件
// import HomePage from "./HomePage/HomePage";
// import ProjectPage from "./ProjectPage/ProjectPage";
// import TeamPage from "./TeamPage/TeamPage";
// import WorkPage from "./WorkPage/WorkPage";
// import ServicesPage from "./ServicesPage/ServicesPage";
// import ContactPage from "./ContactPage/ContactPage";
// import CareersPage from "./CareersPage/CareersPage";
// import DigitalSolutionsPage from "./DigitalSolutionsPage/DigitalSolutionsPage";
// 懒加载方式导入路由组建
const HomePage = lazy(() => import("./HomePage/HomePage"));
const ShoWreel = lazy(() => import("./HomePage2/HomePage"));
const ProjectPage = lazy(() =>  import("./ProjectPage/ProjectPage"));
const TeamPage = lazy(() =>  import("./TeamPage/TeamPage"));
const WorkPage = lazy(() =>  import("./WorkPage/WorkPage"));
const ServicesPage = lazy(() =>  import("./ServicesPage/ServicesPage"));
const ContactPage = lazy(() =>  import("./ContactPage/ContactPage"));
const CareersPage = lazy(() =>  import("./CareersPage/CareersPage"));
const DigitalSolutionsPage = lazy(() => import("./DigitalSolutionsPage/DigitalSolutionsPage"));
const RdPage = lazy(() => import("./Rdevelopment/Rdevelopment"))
const UsPage = lazy(() => import("./UsPage/UsPage"))


class Pages extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.prepAnimation()
            if ('scrollRestoration' in window.history) {
                window.history.scrollRestoration = 'manual';
            }
        }
    }
    prepAnimation = () => { // Get ready for the CSS animation
        let wrapper = document.querySelector('.wrapper:last-of-type');
        wrapper.style.height = '100vh'
        let offset = document.documentElement.scrollTop
        let page = document.querySelector('.wrapper:last-of-type .page')
        page.style.top = '-' + offset + 'px'
        // Set scroll top top
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <TransitionGroup>
                <CSSTransition key={this.props.location.key} timeout={2000} className="wrapper">
                    <div className={"wrapper"}>
                        <div className={"page"}>
                            <Suspense fallback={<Loading/>}>
                                <Switch location={this.props.location}>
                                    {/* 匹配根路径 exact不要随便添加严格匹配需要再加,随意使用可能导致无法匹配二级路由*/}
                                    <Route exact path={"/"}>
                                        {/* 主页面 */}
                                        <HomePage/>
                                    </Route>
                                    <Route exact path={"/ShoWreel"}>
                                        {/* 主页面 */}
                                        <ShoWreel/>
                                    </Route>
                                    <Route path={"/Interactive"}>
                                        {/* 项目列表页面 */}
                                        <WorkPage />
                                    </Route>
                                    <Route  path={"/project/:name"}>
                                        {/* 单个项目详情页面 */}
                                        <ProjectPage/>
                                    </Route>
                                    <Route path={"/WebDevelopment"}>
                                        {/* 数字解决方案（技术领域）页面 */}
                                        <DigitalSolutionsPage />
                                    </Route>
                                    <Route path={"/services"}>
                                        {/* 可提供的服务内容页面 */}
                                        <ServicesPage />
                                    </Route>
                                    <Route path={"/team"}>
                                        {/* 公司成员页面 */}
                                        <TeamPage />
                                    </Route>
                                    <Route path={"/careers"}>
                                        {/* 公司生涯页面 */}
                                        <CareersPage />
                                    </Route>
                                    <Route path={"/contact"}>
                                        {/* 联系我们页面 */}
                                        <ContactPage />
                                    </Route>
                                    <Route path={"/Rnd"}>
                                        {/* r&d页面 */}
                                        <RdPage />
                                    </Route>
                                    
                                    <Route path={"/us"}>
                                        {/* us页面 */}
                                        <UsPage />
                                    </Route>
                                </Switch>
                            </Suspense>
                            {/* 页面底部内容是固定的 */}
                            <Footer />
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>

        )

    }

}

export default withRouter(Pages)